import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n  type=\"button\"\n  class=\"btn btn-secondary btn-sm text-primary\"\n  {{on \"click\" (perform this.downloadFile @path @fileName)}}\n  ...attributes\n>\n  {{yield}}\n</button>", {"contents":"<button\n  type=\"button\"\n  class=\"btn btn-secondary btn-sm text-primary\"\n  {{on \"click\" (perform this.downloadFile @path @fileName)}}\n  ...attributes\n>\n  {{yield}}\n</button>","moduleName":"client-app-omnivise-web/components/force-download-button.hbs","parseOptions":{"srcName":"client-app-omnivise-web/components/force-download-button.hbs"}});
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import fetch from 'fetch';

export default class ForceDownloadButtonComponent extends Component {
  @task({ maxConcurrency: 1 })
  *downloadFile(path, fileName) {
    const response = yield fetch(path, {
      headers: {
        'Content-Type': 'text/txt; charset=utf-8',
        'Cache-Control': 'no-cache',
      },
    });
    const blob = yield response.blob();

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    link.remove();
  }
}
