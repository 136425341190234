import Model from '@ember-data/model';
import { hasMany, attr } from '@ember-data/model';
import normalizeLocaleToApi from 'client-app-omnivise-web/utils/get-normalized-locale';
import { inject as service } from '@ember/service';

export default class ModuleCategoryModel extends Model {
  @service intl;

  @attr('string') 'title_en-US';
  @attr('string') 'title_de-DE';
  @hasMany('module', { inverse: 'moduleCategories', async: false }) modules;

  get title() {
    return this[`title_${normalizeLocaleToApi(this.intl.primaryLocale)}`];
  }
}
