import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{did-update-helper\n  this.handleAuthenticationChange\n  this.session.isAuthenticated\n}}\n\n<Header::TopLine @currentModule={{this.currentPage.header}}>\n\n  {{#if this.session.isAuthenticated}}\n    <div class=\"d-flex flex-row align-items-md-center gap-2 me-2 ms-md-4\">\n      <Header::ModulesLauncher />\n      <NotificationsManager />\n      <HelpMaterials />\n      <OmniviseTickets />\n\n      <Header::Tooltip />\n      <Header::SpecialMyHealthButton />\n\n      <Header::UserMenu />\n    </div>\n  {{else}}\n    {{! Here would go public page behavior }}\n  {{/if}}\n\n</Header::TopLine>\n\n<TokenExpiredModal />", {"contents":"{{did-update-helper\n  this.handleAuthenticationChange\n  this.session.isAuthenticated\n}}\n\n<Header::TopLine @currentModule={{this.currentPage.header}}>\n\n  {{#if this.session.isAuthenticated}}\n    <div class=\"d-flex flex-row align-items-md-center gap-2 me-2 ms-md-4\">\n      <Header::ModulesLauncher />\n      <NotificationsManager />\n      <HelpMaterials />\n      <OmniviseTickets />\n\n      <Header::Tooltip />\n      <Header::SpecialMyHealthButton />\n\n      <Header::UserMenu />\n    </div>\n  {{else}}\n    {{! Here would go public page behavior }}\n  {{/if}}\n\n</Header::TopLine>\n\n<TokenExpiredModal />","moduleName":"client-app-omnivise-web/components/header/index.hbs","parseOptions":{"srcName":"client-app-omnivise-web/components/header/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { runTask } from 'ember-lifeline';

export default class HeaderComponent extends Component {
  @service session;
  @service currentPage;

  @action
  handleAuthenticationChange() {
    // Don't ask me why we need to delay this to the next runloop. I guess
    // because we execute this function by a helper. All I know is that _not_
    // delaying it to the next runloop invalidates `isAuthenticated`, which
    // leads to an endless loop.
    runTask(
      this,
      () => {
        const { isAuthenticated } = this.session;
        if (!isAuthenticated) {
          this.showDetails = null;
        }
      },
      1,
    );
  }
}
