import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'client-app-omnivise-web/config/environment';
import * as Sentry from '@sentry/ember';

// It will be derived from process.env and then would be changed as booleans,
// or it might be a string in case of ONPREM build and have to be manually checked like this
const checkIsFeatureEnabled = (config, featureName) => {
  const isEnvVarValue =
    typeof config.featureFlags[featureName] === 'string' &&
    config.featureFlags[featureName].toLowerCase() === 'true';
  const isBooleanValue =
    typeof config.featureFlags[featureName] === 'boolean' &&
    config.featureFlags[featureName];

  return isEnvVarValue || isBooleanValue;
};

// Due to how early this file is executed, we need to check the feature flag from config file, not service
if (checkIsFeatureEnabled(config, 'sentry')) {
  Sentry.init({
    dsn: config.sentryDSN,
    integrations: [
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production,
    tracesSampleRate: 0.5,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // TODO: remove it before merge
    debug: true,
    environment: config.instanceConfiguration.envName,
    browserTracingOptions: {
      tracePropagationTargets: [
        'visualization-components.omnivise.siemens-energy.com/',
        'svc-api-gateway.omnivise-dev.siemens-energy.cloud',
        'svc-api-gateway.omnivise-qa.siemens-energy.cloud',
        'svc-api-gateway.omnivise.siemens-energy.cloud',
      ],
    },
  });
}

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
