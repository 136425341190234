import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import normalizeLocaleToApi from 'client-app-omnivise-web/utils/get-normalized-locale';

export default class extends Model {
  @service intl;

  // the following lines will be autogenerated by
  // @localizedAttr benefits;
  @attr({ defaultValue: () => [] }) 'benefits_de-DE';
  @attr({ defaultValue: () => [] }) 'benefits_en-US';
  @attr('string') contactEmail;
  @attr('string') contactFirstName;
  @attr('string') contactLastName;
  @attr('string') contactPhoneNumber;
  @attr({ defaultValue: () => [] }) images;

  @belongsTo('module', { inverse: 'informationDetail', async: true }) module;

  get benefits() {
    return this[`benefits_${normalizeLocaleToApi(this.intl.primaryLocale)}`];
  }
  get contactFullName() {
    return `${this.contactFirstName} ${this.contactLastName}`;
  }
}
