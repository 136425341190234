import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{did-update-helper this.onAuthenticationChange this.session.isAuthenticated}}", {"contents":"{{did-update-helper this.onAuthenticationChange this.session.isAuthenticated}}","moduleName":"client-app-omnivise-web/components/redirect-to-auth-if-authenticated.hbs","parseOptions":{"srcName":"client-app-omnivise-web/components/redirect-to-auth-if-authenticated.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 * This component observes the `isAuthenticated` state of session service. If
 * it changes to `true`, the component triggers a transition to `auth` route.
 *
 * Changes to `isAuthenticated` state are observed using `{{did-update-helper}}`
 * helper from `ember-render-helpers`.
 *
 * Conceptually this is an observer pattern. But observers aren't part of
 * Ember's programming modal anymore since Octane. So kind of misuing a
 * component to have an observer style pattern.
 *
 * Ember is currently missing a good pattern for this kind of situations.
 * There as been a proposal to add `@use` and `Resources` for this use case.
 * But it's still an experiment. We might want to refactor to that one when
 * it's more stable. Please find more information about it in this RFC
 * https://github.com/emberjs/rfcs/pull/567 and this blog post
 * https://www.pzuraq.com/introducing-use/.
 */
export default class RedirectToAuthIfAuthenticated extends Component {
  @service session;
  @service router;

  @action
  async onAuthenticationChange() {
    if (this.session.isAuthenticated) {
      if (!this.session.redirectUri) {
        const { redirectUri } = this.router.currentRoute.queryParams;
        this.session.redirectUri = redirectUri;
      }
      await this.session.redirectToDestinationAfterAuthentication();
    }
  }
}
